import React, { useEffect, useState } from 'react';
import Footer from "./footer";
import {
    ArrowRight, FileUp, Settings, Cpu, BarChart, Globe, X, Eye, EyeOff,
    User, Mail, Phone, Flag, Languages, Linkedin, Twitter
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import EnhancedFeaturesMetrics from "./features";
import AutoDemo from "./animation";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
} from 'firebase/auth';
import { setDoc, doc } from 'firebase/firestore';
import { auth, db } from "../firebase";

const Home = ({ user }) => {
    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isRegister, setIsRegister] = useState(true);
    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        email: '',
        password: '',
        whatsapp: '',
        country: '',
        languagePairs: '',
        socialMedia: ''
    });

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
            const user = userCredential.user;
            const userData = {
                name: formData.name,
                surname: formData.surname,
                email: formData.email,
                whatsapp: formData.whatsapp || null,
                country: formData.country,
                languagePairs: formData.languagePairs,
                socialMedia: formData.socialMedia || null,
                createdAt: new Date(),
            };
            await setDoc(doc(db, "users", user.uid), userData);
            setShowPopup(false);
            navigate("/dashboard");
        } catch (error) {
            console.error("Error during registration:", error);
            alert(error.message);
        }
    };

    const handleSignIn = async (e) => {
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, formData.email, formData.password);
            setShowPopup(false);
            navigate("/dashboard");
        } catch (error) {
            console.error("Error during sign in:", error);
            alert(error.message);
        }
    };

    const handleSubmit = isRegister ? handleRegister : handleSignIn;

    const handleStartNow = () => {
        if (user) {
            navigate("/dashboard");
        } else {
            setShowPopup(true);
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-black text-white">
            <main className="container mx-auto px-6 sm:px-12 py-12">
                {/* Hero Section */}
                <section className="relative grid sm:grid-cols-2 grid-cols-1 py-16 mb-20">
                    <div className="flex items-center">
                        <div>
                            <h1 className="text-5xl font-bold mb-6 leading-tight">
                                Build Your Own Neural Machine Translator
                            </h1>
                            <p className="text-xl text-gray-300 mb-8 max-w-2xl">
                                No coding required. Train, deploy, and commercialize your neural machine translator with just a few clicks.
                            </p>
                            <button
                                onClick={handleStartNow}
                                className="relative inline-flex items-center justify-center px-8 py-3 font-bold text-white bg-gradient-to-r from-blue-600 to-indigo-400 rounded-full shadow-lg transform transition-transform hover:scale-105 hover:from-indigo-500 hover:to-blue-500"
                            >
                                <span>Start Now</span> <ArrowRight className="ml-2" />
                            </button>
                        </div>
                    </div>
                    <div className="flex justify-center sm:mx-12 mt-10 relative">
                        <div className="absolute inset-0 bg-gradient-to-br from-blue-600 to-indigo-600 opacity-20 rounded-lg"></div>
                        <AutoDemo />
                    </div>
                    {/* Decorative Element */}
                    <div className="absolute top-0 right-0 w-64 h-64 bg-gradient-to-tr from-blue-600 to-indigo-600 opacity-30 rounded-full -mt-32 -mr-32 blur-2xl"></div>
                </section>

                {/* Features Section */}
                <EnhancedFeaturesMetrics />

                {/* How It Works Section */}
                <AnimatedHowItWorks />

                {/* Call to Action */}
                <section className="text-center mb-20">
                    <h2 className="text-3xl font-semibold mb-6">Ready to build your translator?</h2>
                    <button
                        onClick={handleStartNow}
                        className="relative inline-flex items-center justify-center px-8 py-3 font-bold text-white bg-gradient-to-r from-indigo-600 to-blue-600 rounded-full shadow-lg transform transition-transform hover:scale-105 hover:from-indigo-500 hover:to-blue-500"
                    >
                        <span>Start Now</span> <ArrowRight className="ml-2" />
                    </button>
                </section>
            </main>

            {/* Footer */}
            <Footer />

            {/* Registration & Sign In Popup */}
            {showPopup && (
                <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50">
                    <div className="relative bg-gray-900 text-white p-8 rounded-3xl max-w-md w-full shadow-2xl">
                        <button
                            onClick={() => setShowPopup(false)}
                            className="absolute top-4 right-4 text-gray-400 hover:text-gray-200"
                            aria-label="Close"
                        >
                            <X size={24} />
                        </button>
                        <div className="text-center mb-6">
                            <h2 className="text-2xl font-semibold">
                                {isRegister ? "Create an Account" : "Welcome Back"}
                            </h2>
                            <p className="text-gray-400">
                                {isRegister ? "Join us and start building your translator." : "Sign in to continue."}
                            </p>
                        </div>
                        <div className="flex justify-center mb-6">
                            <button
                                onClick={() => setIsRegister(true)}
                                className={`px-4 py-2 mr-2 font-semibold ${isRegister ? 'bg-indigo-600 text-white' : 'bg-gray-700 text-gray-300'} rounded-l-full transition-colors duration-300`}
                            >
                                Register
                            </button>
                            <button
                                onClick={() => setIsRegister(false)}
                                className={`px-4 py-2 font-semibold ${!isRegister ? 'bg-indigo-600 text-white' : 'bg-gray-700 text-gray-300'} rounded-r-full transition-colors duration-300`}
                            >
                                Sign In
                            </button>
                        </div>
                        <form onSubmit={handleSubmit} className="space-y-5">
                            {isRegister && (
                                <>
                                    <InputField
                                        icon={<User className="text-gray-400" />}
                                        name="name"
                                        type="text"
                                        placeholder="First Name"
                                        required
                                        onChange={handleInputChange}
                                    />
                                    <InputField
                                        icon={<User className="text-gray-400" />}
                                        name="surname"
                                        type="text"
                                        placeholder="Last Name"
                                        required
                                        onChange={handleInputChange}
                                    />
                                </>
                            )}
                            <InputField
                                icon={<Mail className="text-gray-400" />}
                                name="email"
                                type="email"
                                placeholder="Email"
                                required
                                onChange={handleInputChange}
                            />
                            <InputField
                                icon={<Settings className="text-gray-400" />}
                                name="password"
                                type={showPassword ? "text" : "password"}
                                placeholder="Password"
                                required
                                onChange={handleInputChange}
                                togglePasswordVisibility={togglePasswordVisibility}
                                showPassword={showPassword}
                            />
                            {isRegister && (
                                <>
                                    <InputField
                                        icon={<Phone className="text-gray-400" />}
                                        name="whatsapp"
                                        type="tel"
                                        placeholder="WhatsApp Number (Optional)"
                                        onChange={handleInputChange}
                                    />
                                    <InputField
                                        icon={<Flag className="text-gray-400" />}
                                        name="country"
                                        type="text"
                                        placeholder="Country"
                                        required
                                        onChange={handleInputChange}
                                    />
                                    <InputField
                                        icon={<Languages className="text-gray-400" />}
                                        name="languagePairs"
                                        type="text"
                                        placeholder="Language Pairs (e.g., English - Navajo)"
                                        required
                                        onChange={handleInputChange}
                                    />
                                    <InputField
                                        icon={
                                            <div className="flex space-x-1 text-gray-400">
                                                <Linkedin size={18} />
                                                <Twitter size={18} />
                                            </div>
                                        }
                                        name="socialMedia"
                                        type="text"
                                        placeholder="LinkedIn or Twitter Profile (Optional)"
                                        onChange={handleInputChange}
                                    />
                                </>
                            )}
                            <button
                                type="submit"
                                className="w-full py-3 font-semibold rounded-full bg-gradient-to-r from-blue-600 to-indigo-500 text-white shadow-md transform transition-transform hover:scale-105"
                            >
                                {isRegister ? "Register" : "Sign In"}
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Home;

// Reusable InputField Component
const InputField = ({ icon, togglePasswordVisibility, showPassword, ...props }) => {
    return (
        <div className="relative">
            <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
                {icon}
            </span>
            <input
                {...props}
                className="pl-10 w-full p-3 bg-gray-800 text-white border border-gray-700 rounded-lg focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 outline-none transition-colors duration-300"
            />
            {props.name === "password" && (
                <button
                    type="button"
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                    onClick={togglePasswordVisibility}
                    aria-label="Toggle Password Visibility"
                >
                    {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                </button>
            )}
        </div>
    );
};

// AnimatedHowItWorks Component remains the same or can be similarly enhanced
const AnimatedHowItWorks = () => {
    const [activeStep, setActiveStep] = useState(0);
    const steps = [
        { icon: FileUp, text: "Upload your parallel data CSV file to the dashboard" },
        { icon: Settings, text: "Configure your model parameters and hyperparameters" },
        { icon: Cpu, text: "Click \"Start Training\" and let our GPUs do the work" },
        { icon: BarChart, text: "Review training metrics and BLEU scores" },
        { icon: Globe, text: "Use your deployed model via dashboard or API" }
    ];

    useEffect(() => {
        const timer = setInterval(() => {
            setActiveStep((prevStep) => (prevStep + 1) % steps.length);
        }, 3000);
        return () => clearInterval(timer);
    }, []);

    return (
        <section className="relative bg-gray-800 rounded-3xl shadow-lg px-8 pt-8 pb-12 mb-20">
            {/* Decorative Background */}
            <div className="absolute inset-0 bg-gradient-to-tr from-indigo-900 via-transparent to-gray-800 opacity-50 rounded-3xl pointer-events-none"></div>
            <h2 className="text-3xl font-semibold text-center mb-12">How It Works</h2>
            <div className="relative">
                <div
                    className="absolute left-1/2 transform -translate-x-1/2 w-1 bg-indigo-500 transition-all duration-1000 ease-in-out"
                    style={{ height: `${(activeStep + 1) * 20}%` }}
                />
                <ol className="relative z-10">
                    {steps.map((step, index) => {
                        const isActive = index === activeStep;
                        return (
                            <li key={index} className={`flex items-center mb-12 ${index % 2 === 0 ? 'flex-row-reverse' : ''}`}>
                                <div
                                    className={`flex-1 ${index % 2 === 0 ? 'text-right pr-8' : 'pl-8'} transition-all duration-500`}
                                    style={{
                                        opacity: isActive ? 1 : 0.5,
                                        transform: isActive ? 'translateY(0)' : 'translateY(20px)'
                                    }}
                                >
                                    <p className={`text-lg ${isActive ? 'font-semibold text-white' : 'text-gray-500'}`}>{step.text}</p>
                                </div>
                                <div
                                    className={`flex-shrink-0 w-14 h-14 rounded-full flex items-center justify-center z-10 transition-all duration-500
                    ${isActive ? 'bg-indigo-600 shadow-lg' : 'bg-indigo-300'}`}
                                    style={{
                                        transform: isActive ? 'scale(1.1)' : 'scale(1)'
                                    }}
                                >
                                    <step.icon size={28} className="text-white" />
                                </div>
                                <div className={`flex-1 ${index % 2 === 0 ? 'pl-8' : 'pr-8'}`}></div>
                            </li>
                        );
                    })}
                </ol>
            </div>
        </section>
    );
};
