import React from 'react';
import { Upload, Globe, Code, Server, Cpu, Sliders, Zap } from 'lucide-react';
import Header from './header';
import Footer from "./footer"

const Docs = () => {
    const parameters = [
        {
            name: 'Input File',
            icon: Upload,
            description: 'The CSV file containing your training data. This should include source sentences and their corresponding translations.',
            details: (
                <>
                    Ensure your CSV is properly formatted with columns for source and target languages. Make sure your file has at least 10 thousand parallel sentences. <a href="https://gaiacloud-one.s3.us-west-2.amazonaws.com/training_data.csv" className="text-blue-500 hover:underline">Click here to download sample training data in Aguaruna (Low-resource language) and Spanish (Dominant or well estabilished language)</a>.
                </>
            )
        },
        {
            name: 'Source Language',
            icon: Globe,
            description: 'The language of the input text you want to translate from.',
            details: (
                <>
                    Select from a wide range of supported languages or type in your language in BCP-47 format followed by the writing system. e.g. for Spanish in the Latin writing system is spa_Latn.
                </>
            )
        },
        {
            name: 'Target Language',
            icon: Globe,
            description: 'The language you want your text to be translated into.',
            details: (
                <>
                    Select from a wide range of supported languages. The target language will be a dominant or well estabilished language like English, Spanish, Russian, etc..
                </>
            )
        },
        {
            name: 'Tokenizer',
            icon: Code,
            description: 'A pre-existing tokenizer to process your text data.',
            details: 'Generally, it will be the same as your target language, the dominant or well estabilished language, like English, Spanish, Russian, Chinese, etc.'
        },
        {
            name: 'Batch Size',
            icon: Cpu,
            description: 'The number of training examples used in one iteration.',
            details: 'Larger batch sizes can lead to faster training but require more memory. Smaller batch sizes can offer better generalization. Common values range from 16 to 128.'
        },
        {
            name: 'Max Sequence Length',
            icon: Sliders,
            description: 'The maximum number of tokens in a single input sequence.',
            details: 'Longer sequences allow for more context but increase memory usage and computation time. Typical values range from 128 to 512 tokens.'
        },
        {
            name: 'Warmup Steps',
            icon: Zap,
            description: 'The number of steps for the learning rate warmup phase.',
            details: 'Warmup helps stabilize training in the early stages. It typically ranges from a few hundred to a few thousand steps, depending on your dataset size.'
        },
        {
            name: 'Training Steps',
            icon: Zap,
            description: 'The total number of training iterations.',
            details: 'More steps allow for longer training, potentially improving results but taking more time. The optimal number depends on your dataset size and complexity.'
        },
        {
            name: 'Learning Rate',
            icon: Sliders,
            description: 'Controls how much to change the model in response to the estimated error each time the model weights are updated.',
            details: 'Typical values range from 1e-5 to 1e-3. A higher learning rate leads to faster learning but risks overshooting optimal weights, while a lower rate provides more stability but slower learning.'
        },
        {
            name: 'Weight Decay',
            icon: Sliders,
            description: 'A regularization technique to prevent overfitting.',
            details: 'Common values range from 1e-4 to 1e-2. Higher values increase regularization, potentially reducing overfitting but may lead to underfitting if set too high.'
        }
    ];

    return (
        <div className='bg-gradient-to-b from-gray-900 via-gray-800 to-black text-white'>
            <div className="max-w-4xl pt-16 mx-auto p-6">
                <div className="mb-8">
                    <p className="mb-4">
                        Welcome to the this guide for using Gaia. It will help you understand each parameter involved in the training process, ensuring you can make informed decisions to optimize your model's performance.
                    </p>
                    <p className="mb-4">
                        The success of your translation model depends greatly on how you configure these parameters. Each one plays a crucial role in the learning process, affecting aspects such as training speed, model accuracy, and generalization capability.
                    </p>
                    <p>
                        Let's explore each parameter in detail:
                    </p>
                </div>

                <div className="space-y-8">
                    {parameters.map((param, index) => (
                        <div key={index} className="p-6">
                            <div className="flex items-center mb-4">
                                <param.icon className="w-8 h-8 mr-4 text-blue-500" />
                                <h3 className="text-xl font-semibold text-white text--800">{param.name}</h3>
                            </div>
                            <p className="text--700 text-white mb-3">{param.description}</p>
                            <p className="text-sm text-white text--600">{param.details}</p>
                        </div>
                    ))}
                </div>

                <div className="mt-8 p-6 ">
                    <h3 className="text-xl font-semibold text-blue-400 mb-4">Putting It All Together</h3>
                    <p className="text--700 text-white mb-3">
                        As you configure these parameters, remember that they often interact with each other. For example, increasing the batch size might allow you to use a higher learning rate, while a longer max sequence length might require you to reduce the batch size due to memory constraints.
                    </p>
                    <p className="text--700 mb-3">
                        It's often beneficial to start with default values and then experiment with different configurations. Keep track of your experiments and their results to find the optimal settings for your specific translation task.
                    </p>
                    <p className="text--700">
                        Remember, the best configuration can vary depending on your specific dataset, language pair, and available computational resources. Don't hesitate to iterate and fine-tune these parameters to achieve the best possible translation quality.
                    </p>
                </div>
            </div>
<Footer/>
</div>
    );
};

export default Docs;