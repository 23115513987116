import React, { useState, useRef, useEffect } from 'react';
import { Upload, Play, Globe, Check, Plus, Cpu, Sliders, Zap, Loader } from 'lucide-react';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, serverTimestamp, setDoc, doc, getDocs, updateDoc } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAOYiOFVlyrhxtWYxn8J4yvCbFOdSvXYkI",
    authDomain: "gaia-b6c3d.firebaseapp.com",
    projectId: "gaia-b6c3d",
    storageBucket: "gaia-b6c3d.appspot.com",
    messagingSenderId: "143445699815",
    appId: "1:143445699815:web:58ff7938215492a1414642",
    measurementId: "G-MPTVCFN0TJ"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const languages = [
    { name: 'English', mosesnormalizer: "en", code: 'eng', token: 'eng_Latn' },
    { name: 'Finnish', mosesnormalizer: "fi", code: 'fin', token: 'fin_Latn' },
    { name: 'Spanish', mosesnormalizer: "es", code: 'spa', token: 'spa_Latn' },
    { name: 'French', mosesnormalizer: "fr", code: 'fra', token: 'fra_Latn' },
    { name: 'German', mosesnormalizer: "de", code: 'deu', token: 'deu_Latn' },
    { name: 'Italian', mosesnormalizer: "it", code: 'ita', token: 'ita_Latn' },
    { name: 'Portuguese', mosesnormalizer: "pt", code: 'por', token: 'por_Latn' },
    { name: 'Russian', mosesnormalizer: "ru", code: 'rus', token: 'rus_Cyrl' },
    { name: 'Chinese', mosesnormalizer: "zh", code: 'zho', token: 'zho_Hans' },
    { name: 'Japanese', mosesnormalizer: "ja", code: 'jpn', token: 'jpn_Jpan' },
    { name: 'Korean', mosesnormalizer: "ko", code: 'kor', token: 'kor_Hang' },
    { name: 'Arabic', mosesnormalizer: "ar", code: 'ara', token: 'ara_Arab' },
    { name: 'Hindi', mosesnormalizer: "hi", code: 'hin', token: 'hin_Deva' },
    { name: 'Bengali', mosesnormalizer: "bn", code: 'ben', token: 'ben_Beng' },
    { name: 'Dutch', mosesnormalizer: "nl", code: 'nld', token: 'nld_Latn' },
    { name: 'Turkish', mosesnormalizer: "tr", code: 'tur', token: 'tur_Latn' },
    { name: 'Polish', mosesnormalizer: "pl", code: 'pol', token: 'pol_Latn' },
    { name: 'Swedish', mosesnormalizer: "sv", code: 'swe', token: 'swe_Latn' },
    { name: 'Vietnamese', mosesnormalizer: "vi", code: 'vie', token: 'vie_Latn' },
    { name: 'Thai', mosesnormalizer: "th", code: 'tha', token: 'tha_Thai' },
    { name: 'Greek', mosesnormalizer: "el", code: 'ell', token: 'ell_Grek' },
];

const Training = ({ user, trainingStatus, setTrainingStatus }) => {
    const [instances, setInstances] = useState([]);
    const [availableInstance, setAvailableInstance] = useState(null);
    const [sourceLangInput, setSourceLangInput] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [targetLangInput, setTargetLangInput] = useState('');
    const [similarLangInput, setSimilarLangInput] = useState('');


    const [config, setConfig] = useState({
        userUid: user?.uid,
        sourceLang: sourceLangInput,
        targetLang: targetLangInput,
        path: "",
        mosesPunctNormalizer: '',
        batchSize: 16,
        similarLanguage: "fin_Latn",
        maxLength: 128,
        warmupSteps: 1000,
        trainingSteps: 2000,
        learningRate: 1e-4,
        weightDecay: 1e-3,
    });

    const [inputFile, setInputFile] = useState(null);

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        if (type === 'file') {
            setInputFile(e.target.files[0]);
        } else {
            setConfig(prevConfig => ({
                ...prevConfig,
                [name]: value
            }));
        }
    };

    useEffect(() => {
        const fetchInstances = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "instances"));
                const instancesData = [];
                querySnapshot.forEach((doc) => {
                    instancesData.push({ id: doc.id, ...doc.data() });
                });
                setInstances(instancesData);
                console.log(instancesData.find(instance => !instance.active) || "None")
                const inactiveInstance = instancesData.find(instance => !instance.active);
                setAvailableInstance(inactiveInstance || null);
            } catch (err) {
                console.error("Error fetching instances: ", err);
            }
        };

        fetchInstances();
    }, []);

    const [userDefinedLanguages, setUserDefinedLanguages] = useState([]);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);

    const handleTargetLangChange = (e) => {
        setTargetLangInput(e.target.value);
    };

    const handleSimilarLangChange = (e) => {
        setSimilarLangInput(e.target.value);
    };

    const handleSourceLangChange = (e) => {
        setSourceLangInput(e.target.value);
        setIsDropdownVisible(true);
    };

    const handleAddUserDefinedLanguage = () => {
        if (sourceLangInput.trim() !== '') {
            const newLanguage = sourceLangInput.trim();
            setUserDefinedLanguages([...userDefinedLanguages, newLanguage]);
            setSelectedLanguage(newLanguage);
            setSourceLangInput(newLanguage);
            setIsDropdownVisible(false);
        }
    };

    const filteredLanguages = languages.filter(lang =>
        lang.name.toLowerCase().includes(targetLangInput.toLowerCase()) ||
        lang.token.toLowerCase().includes(targetLangInput.toLowerCase())
    );

    const handleSelectLanguage = (lang) => {
        setSourceLangInput(lang);
        setSelectedLanguage(lang);
        setIsDropdownVisible(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const saveDeployment = async(uniquePath, source, target, similar) => {
        try {
            const deploymentData = {
                uid: user.uid,
                data: { ...config, path: uniquePath, similarLanguage: similar, sourceLang: source, targetLang: target  },
                timestamp: serverTimestamp(),
                public: false,
                trainingStatus: 'inProgress',
                name: `${user?.displayName?.split(" ")[0]}/${sourceLangInput}-${targetLangInput}`,
            };
            const docRef = await addDoc(collection(db, "models"), deploymentData);
            return docRef.id;
        } catch (error) {
            console.error("Error saving deployment: ", error);
            throw error;
        }
    };
    const [error, setError] = useState("")

    const isFormValid =
        sourceLangInput.trim() &&
        targetLangInput.trim() &&
        config.mosesPunctNormalizer.trim() &&
        inputFile &&
        availableInstance;

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (
            !sourceLangInput.trim() ||
            !targetLangInput.trim() ||
            !config.mosesPunctNormalizer.trim() ||
            !inputFile
        ) {
            alert("Please fill in all fields and select a CSV file.");
            return;
        }

        if (!availableInstance) {
            alert("No available instances to handle the training job.");
            return;
        }

        setError("");
        console.log("Starting");

        const updatedConfig = {
            ...config,
            sourceLang: sourceLangInput,
            targetLang: targetLangInput,
            similarLanguage: "fin_Latn",
        };

        const uniquePath = `${Date.now()}_${sourceLangInput}_${targetLangInput}`;
        const { subdomain, endpoint, instanceId, id } = availableInstance;

        try {
            const res = await fetch(endpoint, { method: "GET" });
            const data = await res.json();
            console.log(data);
            await updateDoc(doc(db, "instances", id), { active: true });
            await new Promise(resolve => setTimeout(resolve, 10000));

            await saveDeployment(uniquePath, updatedConfig.sourceLang, updatedConfig.targetLang, updatedConfig.similarLanguage);

            const formData = new FormData();
            formData.append('inputFile', inputFile);
            Object.keys(updatedConfig).forEach(key => {
                formData.append(key, key === 'path' ? uniquePath : updatedConfig[key]);
            });
            formData.append('instanceId', instanceId);
            setTrainingStatus('inProgress');
            console.log("Sending request to: ", `https://${subdomain}.gaia-ml.com/api/train-nmt/`)
            console.log("Datos enviados: ", formData)
            const response = await fetch(`https://${subdomain}.gaia-ml.com/api/train-nmt/`, {
                method: 'POST',
                body: formData,
            });

            const responseData = await response.json();
            console.log(responseData);

            if (responseData?.error) {
                setTrainingStatus("error");
                setError(responseData.error);
            } else {
                await updateDoc(doc(db, "instances", id), { active: false });
            }
        } catch (error) {
            setError(error.message || "An unexpected error occurred.");
            console.error('Error:', error);
        }
    };


    return (
        <div className="max-w-4xl mx-auto p-6">
            <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
                    <div className="col-span-3">
                        <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="inputFile">
                        </label>
                        <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-lg ${inputFile ? 'border-green-500' : 'border-gray-300'}">
                            <div className="space-y-1 text-center">
                                <Upload className="mx-auto h-12 w-12 text-gray-400" />
                                {inputFile ? (
                                    <div className="flex items-center justify-center">
                                        <p className="text-sm text-green-600 mr-2">{inputFile.name}</p>
                                        <button
                                            type="button"
                                            onClick={() => setInputFile(null)}
                                            className="text-red-500 ml-4 hover:text-red-700 text-sm"
                                        >
                                            Upload a new file
                                        </button>
                                    </div>
                                ) : (
                                    <div className="flex text-sm text-gray-600">
                                        <label htmlFor="inputFile" className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500">
                                            <span>Upload a file</span>
                                            <input
                                                id="inputFile"
                                                name="inputFile"
                                                type="file"
                                                className="sr-only"
                                                onChange={handleChange}
                                                accept=".csv"
                                            />
                                        </label>
                                        <p className="pl-1">or drag and drop</p>
                                    </div>
                                )}
                                <p className="text-xs text-gray-500">CSV file</p>
                            </div>
                        </div>
                    </div>

                    <div className="mb-4 relative" ref={dropdownRef}>
                        <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="sourceLang">
                            Source Language
                        </label>
                        <input
                            type="text"
                            id="sourceLang"
                            name="sourceLang"
                            value={sourceLangInput}
                            onChange={handleSourceLangChange}
                            onFocus={() => setIsDropdownVisible(true)}
                            className="w-full py-2 px-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            placeholder="agr_Latn"
                            autoComplete="off"
                        />
                        {isDropdownVisible && sourceLangInput.trim() !== '' && (
                            <div className="absolute mt-1 w-full bg-white rounded-lg shadow-lg z-10">
                                {languages
                                    .filter(lang =>
                                        lang.name.toLowerCase().includes(sourceLangInput.toLowerCase()) ||
                                        lang.token.toLowerCase().includes(sourceLangInput.toLowerCase())
                                    )
                                    .map(lang => (
                                        <div
                                            key={lang.token}
                                            className="px-3 py-2 cursor-pointer hover:bg-gray-100"
                                            onClick={() => handleSelectLanguage(lang.token)}
                                        >
                                            {lang.name}
                                        </div>
                                    ))}
                                {userDefinedLanguages
                                    .filter(lang => lang.toLowerCase().includes(sourceLangInput.toLowerCase()))
                                    .map(lang => (
                                        <div
                                            key={lang}
                                            className="px-3 py-2 cursor-pointer hover:bg-gray-100"
                                            onClick={() => handleSelectLanguage(lang)}
                                        >
                                            {lang}
                                        </div>
                                    ))}
                                {!languages.some(lang =>
                                    lang.name.toLowerCase() === sourceLangInput.toLowerCase() ||
                                    lang.token.toLowerCase() === sourceLangInput.toLowerCase()
                                ) &&
                                    !userDefinedLanguages.includes(sourceLangInput) && (
                                        <div
                                            key="addNew"
                                            className="px-3 py-2 cursor-pointer hover:bg-gray-100 flex items-center"
                                            onClick={handleAddUserDefinedLanguage}
                                        >
                                            {sourceLangInput} <Plus className="h-4 w-4 ml-2" />
                                        </div>
                                    )}
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="targetLang">
                            Target Language
                        </label>
                        <select
                            id="targetLang"
                            name="targetLang"
                            value={targetLangInput}
                            onChange={handleTargetLangChange}
                            className="w-full py-2 px-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent appearance-none"
                        >
                            <option value="">Select a language</option>
                            {filteredLanguages.map((option) => (
                                <option key={option.token} value={option.token}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                    </div>

  {/*
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="similarLanguage">
                            Similar Language
                        </label>
                        <select
                            id="similarLanguage"
                            name="similarLanguage"
                            value={similarLangInput}
                            onChange={handleSimilarLangChange}
                            className="w-full py-2 px-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent appearance-none"
                        >
                            <option value="">Select a language</option>
                            {filteredSimilarLanguages.map((option) => (
                                <option key={option.token} value={option.token}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                    </div>
  */}

                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="mosesPunctNormalizer">
                            Moses Punctuation Normalizer
                        </label>
                        <select
                            id="mosesPunctNormalizer"
                            name="mosesPunctNormalizer"
                            value={config.mosesPunctNormalizer}
                            onChange={handleChange}
                            className="w-full py-2 px-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent appearance-none"
                        >
                            <option value="">Select a language</option>
                            {languages.map((option) => (
                                <option key={option.mosesnormalizer} value={option.mosesnormalizer}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="batchSize">
                            Batch Size
                        </label>
                        <input
                            type="number"
                            id="batchSize"
                            name="batchSize"
                            value={config.batchSize}
                            onChange={handleChange}
                            className="w-full py-2 px-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="maxLength">
                            Max Sequence Length
                        </label>
                        <input
                            type="number"
                            id="maxLength"
                            name="maxLength"
                            value={config.maxLength}
                            onChange={handleChange}
                            className="w-full py-2 px-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="warmupSteps">
                            Warmup Steps
                        </label>
                        <input
                            type="number"
                            id="warmupSteps"
                            name="warmupSteps"
                            value={config.warmupSteps}
                            onChange={handleChange}
                            className="w-full py-2 px-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="trainingSteps">
                            Training Steps
                        </label>
                        <input
                            type="number"
                            id="trainingSteps"
                            name="trainingSteps"
                            value={config.trainingSteps}
                            onChange={handleChange}
                            className="w-full py-2 px-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="learningRate">
                            Learning Rate
                        </label>
                        <input
                            type="number"
                            id="learningRate"
                            name="learningRate"
                            value={config.learningRate}
                            onChange={handleChange}
                            step="0.0001"
                            className="w-full py-2 px-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="weightDecay">
                            Weight Decay
                        </label>
                        <input
                            type="number"
                            id="weightDecay"
                            name="weightDecay"
                            value={config.weightDecay}
                            onChange={handleChange}
                            step="0.0001"
                            className="w-full py-2 px-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        />
                    </div>
                </div>

                <div className="flex justify-end">
                    {trainingStatus === 'inProgress' && (
                        <div className="mr-4 flex items-center space-x-2">
                            <Loader className='w-4 h-auto animate-spin text-green-500' />
                            <p className='text-green-500'>Training in progress...</p>
                        </div>
                    )}

                    {trainingStatus === 'success' && (
                        <div className="flex items-center mr-4 text-green-500">
                            <Check className="h-5 w-5 mr-2" />
                            Training completed successfully!
                        </div>
                    )}
                    <div className="flex items-center mr-4 text-red-500">
                        No VMs available at this time!
                    </div>
                    {trainingStatus === 'error' && (
                        <div className="mr-4 text-red-500">Error: {error}</div>
                    )}

                    <button
                        type="submit"
                        className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${isFormValid ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-300 cursor-not-allowed'
                            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                        disabled={!isFormValid || trainingStatus === 'inProgress'}
                    >
                        <Play className="h-5 w-5 mr-2" />
                        Start Training
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Training;
