import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { FaLanguage, FaExchangeAlt } from "react-icons/fa";
import { ChevronLeft } from 'lucide-react';

const ModelDetail = () => {
    const { modelId } = useParams();
    const [model, setModel] = useState(null);
    const [error, setError] = useState(null);
    const [text, setText] = useState("");
    const [srcLang, setSrcLang] = useState("");
    const [tgtLang, setTgtLang] = useState("");
    const [translation, setTranslation] = useState(null);
    const [loading, setLoading] = useState(false);
    const [visibilityLoading, setVisibilityLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchModel = async () => {
            try {
                const modelDoc = await getDoc(doc(db, 'sota', modelId));
                if (modelDoc.exists()) {
                    const modelData = { id: modelDoc.id, ...modelDoc.data() };
                    setModel(modelData);
                    setSrcLang(modelData.src_lang);
                    setTgtLang(modelData.tgt_lang);
                } else {
                    setError('Model not found.');
                }
            } catch (err) {
                setError('Failed to fetch model details.');
                console.error(err);
            }
        };

        fetchModel();
    }, [modelId]);

    const updateModelVisibility = async (modelId, newPublicValue) => {
        setVisibilityLoading(true);
        try {
            const modelRef = doc(db, "sota", modelId);
            await updateDoc(modelRef, {
                public: newPublicValue
            });
            setModel({ ...model, public: newPublicValue });
        } catch (error) {
            console.error("Failed to update model visibility:", error);
        } finally {
            setVisibilityLoading(false);
        }
    };

    const handleLanguageSwap = () => {
        setSrcLang(tgtLang);
        setTgtLang(srcLang);
        setText("");
        setTranslation(null);
    };

    const handleTranslate = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setTranslation(null);

        try {
            const response = await fetch(
                `https://fst.gaia-ml.com/api/translate/?text=${encodeURIComponent(text)}&src_lang=${srcLang}&tgt_lang=${tgtLang}&model_folder=${model.file_path}`
            );

            if (!response.ok) {
                throw new Error("Translation request failed");
            }

            const data = await response.json();

            if (data.success && data.data) {
                setTranslation(data.data);
            } else {
                setError("Invalid response format from server");
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    if (error) {
        return <div className="p-8 text-red-600">{error}</div>;
    }

    if (!model) {
        return <div className="p-8">Loading...</div>;
    }

    const toggleStyles = `
        .toggle-checkbox:checked {
            right: 0;
            border-color: #68D391;
        }
        .toggle-checkbox:checked + .toggle-label {
            background-color: #68D391;
        }
    `;

    return (
        <div className="min-h-screen mx-auto sm:px-32 p-8 bg-gradient-to-br from-purple-50 via-indigo-50 to-blue-50">
            <style>{toggleStyles}</style>
            <div className="flex justify-between items-center mb-6">
                <button
                    className="text-indigo-600 hover:text-indigo-800 flex items-center"
                    onClick={() => navigate(-1)}
                >
                    <ChevronLeft size={20} className="mr-1" />
                    Back to Models
                </button>

                <div className="flex items-center space-x-2">
                    <span className='font-medium'>Visibility:</span>
                    <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                        <input
                            type="checkbox"
                            name="toggle"
                            id="toggle"
                            className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                            checked={model.public}
                            disabled={visibilityLoading}
                            onChange={() => {
                                const newPublicValue = !model.public;
                                updateModelVisibility(model.id, newPublicValue);
                            }}
                        />
                        <label
                            htmlFor="toggle"
                            className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                        ></label>
                    </div>
                    <span className="text-sm font-medium">
                        {model.public ?
                            <p className='text-green-500'>True</p> :
                            <p className='text-red-500'>False</p>
                        }
                    </span>
                </div>
            </div>

            <h2 className="text-2xl font-semibold text-indigo-700 mb-6">
                Using Model: {srcLang} → {tgtLang}
            </h2>

            <form onSubmit={handleTranslate} className="space-y-8">
                <div className="flex flex-wrap items-center gap-8">
                    <div className="flex-1">
                        <label className="block text-base font-medium text-indigo-700 mb-3 flex items-center">
                            <FaLanguage className="mr-2" /> Source Language
                        </label>
                        <div className="w-full rounded-xl border border-indigo-200 p-3 bg-white">
                            {srcLang}
                        </div>
                    </div>

                    <div className="flex items-center">
                        <button
                            type="button"
                            onClick={handleLanguageSwap}
                            className="p-3 rounded-full hover:bg-indigo-100 text-indigo-600"
                        >
                            <FaExchangeAlt />
                        </button>
                    </div>

                    <div className="flex-1">
                        <label className="block text-base font-medium text-indigo-700 mb-3 flex items-center">
                            <FaLanguage className="mr-2" /> Target Language
                        </label>
                        <div className="w-full rounded-xl border border-indigo-200 p-3 bg-white">
                            {tgtLang}
                        </div>
                    </div>
                </div>

                <div>
                    <label className="block text-base font-medium text-indigo-700 mb-3 flex items-center">
                        <FaLanguage className="mr-2" /> Text to Translate
                    </label>
                    <textarea
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        rows={4}
                        className="w-full rounded-xl border border-indigo-200 p-4 outline-none"
                        placeholder="Enter text to translate..."
                    />
                </div>

                <div className="flex justify-center">
                    <button
                        type="submit"
                        disabled={loading || !text}
                        className={`rounded-full bg-gradient-to-r from-indigo-600 to-blue-600 py-3 px-8 text-white font-semibold shadow-lg hover:from-indigo-500 hover:to-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-offset-2 transition ${loading || !text ? "opacity-50 cursor-not-allowed" : ""}`}
                    >
                        {loading ? "Translating..." : (
                            <div className="flex items-center">
                                <FaExchangeAlt className="mr-2" />
                                Translate
                            </div>
                        )}
                    </button>
                </div>
            </form>

            {error && (
                <div className="mt-8 p-5 bg-red-100 border border-red-300 rounded-xl text-red-700 shadow-sm">
                    <p>{error}</p>
                </div>
            )}

            {translation && (
                <div className="mt-10 space-y-8">
                    <div className="bg-white shadow-md rounded-2xl p-6">
                        <h3 className="text-xl font-semibold mb-5 text-indigo-600 flex items-center">
                            <FaLanguage className="mr-2" /> Translation Options
                        </h3>
                        <ul className="space-y-4">
                            {translation.translations && translation.translations.map((t, i) => (
                                <li
                                    key={i}
                                    className="p-4 bg-gray-50 rounded-lg border border-gray-200 text-gray-800 shadow-sm"
                                >
                                    {t}
                                </li>
                            ))}
                        </ul>
                    </div>

                    {translation.explanation && (
                        <div className="bg-white shadow-md rounded-2xl p-6">
                            <h3 className="text-xl font-semibold mb-5 text-indigo-600 flex items-center">
                                <FaLanguage className="mr-2" /> Explanation
                            </h3>
                            <p className="text-gray-700">{translation.explanation}</p>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default ModelDetail;
