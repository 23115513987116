import React from 'react';
import { Upload, Sliders, Play, BarChart2, Key, Globe, Code, Zap } from 'lucide-react';

const EnhancedFeaturesMetrics = () => {
    const Feature = ({ icon: Icon, title, description }) => (
        <div className="relative flex flex-col items-center text-center p-6 bg-gray-800 rounded-3xl shadow-lg transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl">
            {/* Decorative Shape */}
            <div className="absolute -top-6 -right-6 w-16 h-16 bg-gradient-to-tr from-blue-500 to-indigo-600 rounded-full blur-lg opacity-30"></div>
            <div className="p-3 bg-gradient-to-br from-blue-600 to-indigo-600 rounded-full mb-4">
                <Icon size={28} className="text-white" />
            </div>
            <h3 className="text-xl font-semibold mb-2 text-white">{title}</h3>
            <p className="text-gray-400 text-sm">{description}</p>
        </div>
    );

    const MetricsCard = () => (
        <div className="relative bg-gray-800 rounded-3xl shadow-lg p-6 transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl">
            {/* Decorative Shape */}
            <div className="absolute -top-8 -left-8 w-20 h-20 bg-gradient-to-br from-purple-600 to-pink-500 rounded-full blur-lg opacity-20"></div>
            <div className="flex items-center mb-6">
                <BarChart2 size={28} className="text-indigo-500 mr-3" />
                <h3 className="text-2xl font-semibold text-white">Performance Metrics</h3>
            </div>
            <div className="space-y-4">
                <div className="bg-gray-700 p-4 rounded-xl">
                    <h4 className="font-semibold mb-1 text-white">English to French</h4>
                    <p className="text-sm text-gray-300">BLEU Score: 38.2</p>
                    <p className="text-sm text-gray-300">chrF Score: 0.65</p>
                </div>
                <div className="bg-gray-700 p-4 rounded-xl">
                    <h4 className="font-semibold mb-1 text-white">Spanish to German</h4>
                    <p className="text-sm text-gray-300">BLEU Score: 35.7</p>
                    <p className="text-sm text-gray-300">chrF Score: 0.62</p>
                </div>
            </div>
        </div>
    );

    const ApiCard = () => (
        <div className="relative bg-gray-800 rounded-3xl shadow-lg p-6 transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl">
            {/* Decorative Shape */}
            <div className="absolute -bottom-8 -right-8 w-20 h-20 bg-gradient-to-br from-green-500 to-teal-500 rounded-full blur-lg opacity-20"></div>
            <div className="flex items-center mb-6">
                <Key size={28} className="text-green-500 mr-3" />
                <h3 className="text-2xl font-semibold text-white">API Access</h3>
            </div>
            <div className="bg-gray-700 p-4 rounded-xl overflow-x-auto">
                <pre className="text-xs text-green-300">
                    <code>
                        {`import requests

API_URL = "https://inference.gaia-cloud.com/api/translate/"
API_KEY = "your_api_key_here"

def translate(text, source_lang, target_lang):
    response = requests.post(API_URL, 
        headers={"Authorization": f"Bearer {API_KEY}"},
        json={
            "text": text,
            "source_language": source_lang,
            "target_language": target_lang
        }
    )
    return response.json()["translation"]

result = translate("Hello to everybody!", "eng_Latn", "spa_Latn")
print(result)  # ¡Hola a todos!`}
                    </code>
                </pre>
            </div>
        </div>
    );

    return (
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
            {/* Decorative Background */}
            <div className="absolute inset-0 bg-gradient-to-br from-gray-900 via-gray-800 to-black opacity-80 pointer-events-none"></div>
            <h2 className="text-4xl font-bold text-center mb-12 text-white relative z-10">Features & Metrics</h2>
            <section className="relative z-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
                <Feature
                    icon={Upload}
                    title="Easy Data Upload"
                    description="Upload your parallel data CSV file with a simple drag-and-drop interface."
                />
                <Feature
                    icon={Sliders}
                    title="Customizable Parameters"
                    description="Fine-tune your model with advanced settings for optimal performance."
                />
                <Feature
                    icon={Play}
                    title="One-Click Training"
                    description="Start training instantly with our powerful NVIDIA GPU infrastructure."
                />
                <Feature
                    icon={Globe}
                    title="Multi-Language Support"
                    description="Train models for a wide range of language pairs, including low-resource languages."
                />
                <Feature
                    icon={Zap}
                    title="Real-Time Monitoring"
                    description="Track training progress and performance metrics in real-time."
                />
                <Feature
                    icon={Code}
                    title="Developer-Friendly"
                    description="Easily integrate your trained model with our comprehensive API."
                />
            </section>

            <section className="relative z-10 grid grid-cols-1 md:grid-cols-2 gap-8">
                <MetricsCard />
                <ApiCard />
            </section>

            {/* Decorative Elements */}
            <div className="absolute top-0 left-0 w-64 h-64 bg-gradient-to-br from-blue-600 to-purple-600 opacity-30 rounded-full -mt-32 -ml-32 blur-2xl"></div>
            <div className="absolute bottom-0 right-0 w-64 h-64 bg-gradient-to-br from-green-600 to-teal-600 opacity-30 rounded-full -mb-32 -mr-32 blur-2xl"></div>
        </div>
    );
};

export default EnhancedFeaturesMetrics;
